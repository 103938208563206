import React from 'react';
import {EBlockPosition, EBlockType, EComponent, IStepComponent, TStepChildPosition, TStepChildType} from '@redux/types';
import {handleAnchorClick, isListItemHaveLink, isNotEmptyRichContent, scrollToElementById} from '@common/functions';
import Text from '@components/Text';
import Link from 'next/link';
import {useActions} from '@common/hooks/useActions';


export default function RichTextRender({content, title, parent, position, type = EBlockType.sticky, classes}: {
  readonly content: any
  parent?: IStepComponent | null
  title?: string,
  position?: TStepChildPosition | null,
  type?: TStepChildType
  classes?: string
}) {

  if (typeof content === 'string') {
    return content
  }

  if (!content || !isNotEmptyRichContent(content)) {
    return (title && (
      <h2 className={`inner-simple-title inner-simple-title--no-margin inner-simple-title--margin-top-40-40-40`}><Text text={title}/></h2>
    ));
  }

  return (
    <>
      {position === EBlockPosition.insideStep || position === EBlockPosition.outsideStep ? (
        <div className={"inner-grid__content"}>
          {title && (<h2 className={`inner-simple-title inner-simple-title--margin-top-40-40-40`}><Text text={title}/></h2>)}
          <Blocks content={content} position={position} type={type} parent={parent}/>
        </div>
      ) : (
        <>
          {title && (<h2 className={`inner-simple-title inner-simple-title--margin-top-40-40-40`}><Text text={title}/></h2>)}
          <Blocks content={content} position={position} type={type} parent={parent} classes={classes}/>
        </>
      )}
    </>
  );
}

const Blocks: React.FC<{
  readonly content: any
  position?: TStepChildPosition | null,
  parent?: IStepComponent | null
  type?: TStepChildType
  classes?: string
}> = ({content, type, position, parent, classes}) => {
  const actions = useActions()
  let initialContent: any[] = [];
  let contentType = '';
  content?.forEach((c: any) => {
    if (contentType !== c?.type) {
      contentType = c?.type || ''
      initialContent.push([c])
    } else {
      initialContent[initialContent?.length - 1]?.push(c)
    }
  })

  return (initialContent?.map((items: any, index) => {
    const Block = ({childrenItems, isStarting, format}: any) => {
      const getContent = (item: any) => {
        const itemType = item?.type as 'paragraph' | 'text' | 'link' | 'heading' | 'list' | 'list-item' | 'quote'
        switch (itemType) {
          case 'paragraph': {
            return (
              !classes ? <p><Block childrenItems={item?.children}/></p> : <p className={classes}><Block childrenItems={item?.children}/></p>
            )
          }
          case 'heading': {
            if (item?.level === 1) {
              return (
                <div className={`inner-simple-text inner-simple-text--font-40-35-20 mt-65 mt-md-40`}><Block childrenItems={item?.children}/></div>
              )
            }
            if (item?.level === 3) {
              return (
                <div className="inner-styled-list__subtitle"><Block childrenItems={item?.children}/></div>
              )
            }
            if (item?.level === 4) {
              return (
                <div className="inner-simple-text mt-65 mt-md-40"><Block childrenItems={item?.children}/></div>
              )
            }
            return (
              <div className={`${_getClasses( index !== 0 || !type ? EBlockType.sticky : type, position || EBlockPosition.insideStep)} mb-65 mb-md-40`}><Block childrenItems={item?.children}/></div>
            )
          }
          case 'quote': {
            return (
              <div className="inner-simple-box inner-simple-box--bg-white inner-simple-box--margin-top-bottom">
                <div className="inner-simple-text inner-simple-text--mobile-margin-big">
                  <Block childrenItems={item?.children}/>
                </div>
              </div>
            )
          }
          case 'link': {
            const isSelf = item.url.includes('127.0.0.1:3000') || item.url.includes('mailto') || item.url.includes('tel')
            if (parent?.__component === EComponent.GRID) {
              return (
                <Link
                  onClick={(e) => handleAnchorClick(e, item?.url)}
                  href={item?.url}
                  className="inner-simple-cards__link"
                  target={isSelf ? '_self' : '_blank'}
                >
                  <Block childrenItems={item?.children}/>
                </Link>
              )
            } else {
              return (
                <Link
                  onClick={(e) => {
                    if (item?.url.includes('#request')) {
                      e.preventDefault()
                      actions.setApp({requestForm: {open: true}})
                    } else {
                      handleAnchorClick(e, item?.url)
                    }
                  }}
                  href={item?.url}
                  className="inner-simple-links__link"
                  target={isSelf ? '_self' : '_blank'}
                >
                  <Block childrenItems={item?.children}/>
                </Link>
              )
            }
          }
          case 'list-item': {
            return (format === 'unordered' ? (
              <li className={isListItemHaveLink(item?.children) ? "inner-simple-links__item" : "inner-simple-list__item"}><p><Block childrenItems={item?.children}/></p></li>
            ) : (
              <li className="inner-navigation-block__navigation-item"><p><Block childrenItems={item?.children}/></p></li>)
            )
          }
          case 'list': {
            return (item?.format === 'unordered' ? (
              <ul className={item?.children?.every((c: any) => isListItemHaveLink(c?.children)) ? "inner-simple-links" :
                !isStarting ? "inner-simple-list inner-simple-list--mobile-margin-big" : "inner-simple-list"}>
                <Block childrenItems={item?.children} format={item?.format}/>
              </ul>
            ) : (
              <ol className={!isStarting ? "inner-navigation-block__navigation-list" : "inner-navigation-block__navigation-list"}>
                <Block childrenItems={item?.children} format={item?.format}/>
              </ol>)
            )
          }
          case 'text': {
            if (item?.bold) {
              return <strong><Block childrenItems={[{...item, bold: false, text: item?.text, type: 'text'}]}/></strong>
            }
            if (item?.italic) {
              return <em><Block childrenItems={[{...item, italic: false, text: item?.text, type: 'text'}]}/></em>
            }
            if (item?.underline) {
              return <u><Block childrenItems={[{...item, underline: false, text: item?.text, type: 'text'}]}/></u>
            }
            if (item?.strikethrough) {
              return <s><Block childrenItems={[{...item, strikethrough: false, text: item?.text, type: 'text'}]}/></s>
            }

            return <Text text={item?.text}/>
          }
          default: return null
        }
      }
      //return null
      return (childrenItems?.type ? getContent(childrenItems) :  childrenItems?.map((item: any, index: number) => {
        return <React.Fragment key={index}>{getContent(item)}</React.Fragment>
      }) || null)
    }

    if (items[0]?.type === 'paragraph' && !!parent && !classes) {
      return (
        <div key={`block_p_${index}`} className={_getClasses( index !== 0 || !type ? EBlockType.sticky : type, position || EBlockPosition.insideStep)}>
          <Block childrenItems={items} isStarting={true}/>
        </div>
      )
    }

    return (
      <Block key={`block_np_${index}`} childrenItems={items} isStarting={items[0]?.type === 'list' && index === 0}/>
    )
  }))
}

const _getClasses = (type: EBlockType, position: EBlockPosition) => (position === EBlockPosition.outsideStep ? 'inner-simple-text step-description inner-simple-text--mobile-margin' : {
  [EBlockType?.grid]:   'inner-simple-text mt-65 mt-md-40 mt-sm-40',
  [EBlockType?.simple]: 'inner-simple-text inner-simple-text--margin-bottom-small inner-simple-text--margin-top-big',
  [EBlockType?.sticky]: 'inner-simple-text mt-25 mt-md-20 mt-sm-5',
  [EBlockType?.table]:  'inner-simple-text',
  [EBlockType?.tools]:  'inner-tools__text',
}[type])
